import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
const { postRole, putRole } = backOffice.roles;
export const scopes = {
  selectedRole: 'selectedRole',
};
const rolesModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  state: {
    data: null,
    [scopes.selectedRole]: {
      data: null,
    },
  },
  actions: {
    async postRole({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postRole,
        params: [data],
      });
    },
    async putRole({ commit }, { roleName, data }) {
      await runAsyncFlow(commit, {
        request: putRole,
        params: [roleName, data],
      });
    },
  },
}));

export default rolesModel;
